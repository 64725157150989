import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/404.scss';
import Breadcrumbs from "../components/Breadcrumbs";
const NotFoundPage = () => {
  const crumbs = [
    {
      title: 'HOME',
      link: '/'
    },
    {
      title: '404 ERROR',
      link: '/404/',
    }
  ]
  return (
    <Layout>
      <SEO title="404: Not found" canonical="/404/" />
      <div className="four-oh-four-container">
        <Breadcrumbs crumbs={crumbs} />
        <div className="four-oh-four-text-container">
          <h4>VINEX</h4>
          <h1>404 Error - <br/>Page Not Found</h1>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage
