import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import '../styles/components/Breadcrumb.scss';

const Breadcrumbs = ({
  crumbs
}) => (
  <div className="crumb-container">
    {crumbs.map((crumb) => (
      <Link to={crumb.link}>{crumb.title}</Link>
    ))}
  </div>
);

Breadcrumbs.propTypes = {
  crumbs: PropTypes.array.isRequired,
};

export default Breadcrumbs;
